import {ITestsRepository} from './ITestsRepository';
import {tests, test} from './TestsQueries';
import {createTest} from './TestMutations';
import {TestUpdate} from '../../graphql/API';
import {updateTest} from '../../graphql/mutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class TestsRepository implements ITestsRepository {
  async getTests(): Promise<any> {
    try {
      let testsList: any[] = [];
      let nextToken: string | null = null;

      do {
        const response: GraphQLResult<any> = await client.graphql({
          query: tests,
          variables: {nextToken},
        });

        // Append the fetched items to the list
        testsList.push(...(response.data?.tests.items || []));

        // Update nextToken for the next request
        nextToken = response.data?.tests.nextToken || null;
      } while (nextToken !== null);

      return testsList;
    } catch (error) {
      console.log('getTests repository Error:', error);
      return []; // Return an empty array if there's an error
    }
  }

  async getTestById(testId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: test,
        variables: {
          id: testId,
        },
      });

      return response.data?.test;
    } catch (error) {
      console.log('Fetch test Error: ', error);
    }
  }

  async createTest(test: any): Promise<any> {
    try {
      const createTestResponse: GraphQLResult<any> = await client.graphql({
        query: createTest,
        variables: {
          input: test,
        },
      });

      return createTestResponse.data?.createTest;
    } catch (error) {
      console.log('Create Test Error: ', error);
    }
  }
  async updateTest(
    id: string,
    test: TestUpdate,
    updatedAt: string,
  ): Promise<any> {
    try {
      const updateTestResponse: GraphQLResult<any> = await client.graphql({
        query: updateTest,
        variables: {
          updatedAt,
          id,
          input: test,
        },
      });

      return updateTestResponse.data?.updateTest;
    } catch (error) {
      console.log('Create Test Error: ', error);
    }
  }
}
